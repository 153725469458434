import chapters from '../data/chapters';
import global from '../data/global';
import Swiper, { Navigation, Thumbs } from '../../node_modules/swiper';

var process; // need to define to access global provided by Webpack

mapboxgl.accessToken = process.env.MAPBOX_TOKEN;
const mapbox = global.mapbox;
const map = new mapboxgl.Map(mapbox);

map.on("load", () => {

    // Insert the layer beneath any symbol layer.
    const layers = map.getStyle().layers;
    const labelLayerId = layers.find(
        (layer) => layer.type === "symbol" && layer.layout["text-field"]
    ).id;

    // The 'building' layer in the Mapbox Streets
    // vector tileset contains building height data
    // from OpenStreetMap.
    map.addLayer({
            id: "add-3d-buildings",
            source: "composite",
            "source-layer": "building",
            filter: ["==", "extrude", "true"],
            type: "fill-extrusion",
            minzoom: 15
        },
        labelLayerId
    );

    // Create clickable markers
    const markers = Object.entries(chapters);
    markers.sort( (a, b) => { return b[1].center[1] - a[1].center[1] });
    markers.forEach( m => {
        const name = $(`#${m[0]} h3`).text(),
            addr = $(`#${m[0]} h4`).text(),
            icon = $(`#${m[0]} i`).attr('class'),         
            collat = $(`#${m[0]} strong`).text();
        const marker = new mapboxgl.Marker({
                color: m[1].type == 'feature' ? '#FDBC18' : '#bababa',
                // scale: 1.5 : 1,
                scale: m[1].type == 'feature' ? '1.5' : '1.3',
            })
            .setLngLat(m[1].center)
            .setPopup(
                new mapboxgl.Popup({
                    offset: 55,
                    className: `marker-popup-${m[1].type}`,
                    closeButton: false,
                    closeOnClick: true
                }).setHTML(
                    `<span class="${icon}"></span><h3>${name}</h3><p>${addr}</p>`
                )
              )
            .addTo(map);
        chapters[m[0]].marker = marker;
        $(marker.getElement()).prop('id', `marker-${m[0]}`).on('click', e => {
            if( $("#features").hasClass("collapseNav") ) {
                closeMarkerPopups();
                map.flyTo(m[1]);
            } else {
                setActiveChapter(m[0]);
            }
        });
    });

    // delay hooking into modal button until map is loaded
    $(".modal button").addClass('active').click( e => {
        $("body").removeClass('has-splash');
        $(".mapboxgl-marker").addClass('loaded');
        mapbox.zoom = 13;
        mapbox.center = [ -77.01064662251372, 38.92992393888633 ];
        mapbox.pitch = 60;
        map.flyTo(mapbox);
        $("#splash").animate({
            opacity: 0
        }, {
            duration: 500,
            complete: function() {
                $(".mapboxgl-marker").addClass('loaded');
                $("#features").toggleClass("collapseNav");
                $("#map").toggleClass("expandMap"); 
                $( this ).remove();
                map.resize();
            }
        });
    });

});

// On every scroll event, check which element is on screen
// Added debounce to prevent scroll until scroll has been finished for 200ms
let scrolling = false,
    scrollTimer = null;
$(window).on('scroll', e => {
    if( scrolling ) return;
    if( scrollTimer ) {
        clearTimeout( scrollTimer );
    }
    scrollTimer = setTimeout(function() {
        if( $("#features").hasClass("listNav") || $("#features").hasClass("collapseNav") ) return;
        for( const c in chapters ) {
            if(isElementOnScreen(c)) {
                setActiveChapter(c);
                break;
            }
        }
    }, 200);
})

let activeChapterName = $('.feature.active').get(0)?.id;

function setActiveChapter(chapterName) {
    if (chapterName === activeChapterName) return;
    scrolling = true;
    $('div.feature.active').removeClass('active');
    $('#'+chapterName).addClass('active');
    location.hash = chapterName;
    closeMarkerPopups();
    chapters[chapterName].marker.togglePopup();
    activeChapterName = chapterName;
    map.flyTo(chapters[chapterName]);
    
    setTimeout(function() {
        scrolling = false;
    }, 200);
}

function closeMarkerPopups() {
    Object.keys(chapters).forEach(c => {
        const marker = chapters[c].marker,
            popup = marker.getPopup();
        popup.remove();
    });
}

function isElementOnScreen(id) {
    const $element = $('#'+id+' .featureblock'),
        elementTop = $element.offset()?.top,
        elementBottom = elementTop + $element.outerHeight(),
        viewTop = $(window).scrollTop(),
        viewBottom = viewTop + window.innerHeight;
    return !(viewBottom < elementTop || viewTop > elementBottom);
}

//debug:
window.map = map;

$("a.reset-map").click( e => {
    e.preventDefault();
    map.once('moveend', function() {
        $("#features").removeClass("collapseNav listNav");
        $("#map").removeClass("expandMap"); 
        $("#about").removeClass("openABT");
        $("#map .mapboxgl-canvas").width( '60vw' );
        $(".content").scrollTop(0);
        location.hash = '';
        activeChapterName = '';
        map.resize();
    });
    mapbox.bearing = -10;
    mapbox.zoom = 13;
    mapbox.center = [-77.0298730675096, 38.91164791237014];
    mapbox.pitch = 60;
    map.flyTo(mapbox);
});

$(".toggle-about").click( e => {
    $("#about").toggleClass("openABT");
});

$(".toggle-list").click( e => {
    $("#features").toggleClass("listNav");
});

$('a.list-nav').click( e => {
    setActiveChapter( $(e.currentTarget).attr('href').replaceAll('#','') );
});

$(".toggle-nav").click( e => {
    $("#features").toggleClass("collapseNav");
    $("#map").toggleClass("expandMap");
    $("#map .mapboxgl-canvas").width( $('#map').hasClass('expandMap') ? '100vw': '50vw' );
    $(".content").scrollTop(0);
    location.hash = '';
    activeChapterName = '';
    $('div.feature.active').removeClass('active');
});

$("#map").bind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", e => {
    map.resize();
});

$(".blurb").click( e => {
    $("#features").toggleClass("expandBlurb");
});

$(".learn").click( e => {
    $("#features").toggleClass("collapseNav");
    $("#map").toggleClass("expandMap"); 
    $("#features").toggleClass("expandABT"); 
    $("#featurenav").toggleClass("expandFNV"); 
    $('.btn.learn').html( $('#features').hasClass('expandABT') ? 'Back to List' : 'Learn More' );
    location.hash = activeChapterName;
});

$(".close").click( e => {
    $("#features").toggleClass("collapseNav");
    $("#map").toggleClass("expandMap"); 
    $("#features").toggleClass("expandABT");   
    $("#featurenav").toggleClass("expandFNV");
    $('.btn.learn').html('Learn More');
}); 

// Swiper JS Thumbs
Swiper.use([Navigation, Thumbs]);

$('.gallery').each( (i, g) => {
    var thumbs = new Swiper( $('.thumb-swiper', g).get(0), {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
    }); 
    var gallery = new Swiper( $('.gallery-swiper', g).get(0), {
        spaceBetween: 10,
        navigation: {
            nextEl: $('.swiper-button-next', g).get(0),
            prevEl: $('.swiper-button-prev', g).get(0),
        },
        thumbs: {
            swiper: thumbs,
        },
    });
});